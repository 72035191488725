/* eslint camelcase: 0, class-methods-use-this: 0 */
import {
  ArrowForwardIcon
} from '@chakra-ui/icons';
import {
  Flex,
  LinkBox,
  LinkOverlay,
  Text
} from '@chakra-ui/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from 'semantic-ui-react/dist/es/elements/Input';
import usePagedList from 'shared/src/hooks/usePagedList';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import useRequest from 'shared/src/hooks/useRequest';
import {
  Empty,
  TableCell,
  TableList,
  TableRow
} from 'web-react-ui/src/chakra/TableList/TableList';

import Button from 'web-react-ui/src/components/elements/Button';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import propertyModule from '../../../modules/property';
import client from '../../../services/client';
import confirm from '../../../services/confirm';

const formatGroupTitle = (name) => {
  switch (name) {
    case 'ios':
      return 'iOS';
    default:
      return _.capitalize(name);
  }
};

const IntegrationItem = ({ integration, propertyId }) => {
  return (
    <LinkBox as={TableRow}>
      <TableCell column={integrationsColumns[0]}>
        <LinkOverlay as={Link} to={`/${propertyId}/integrations/${integration.id}`}>
          <Flex gap="1em" align="center">
            <Text fontWeight="bold">{integration.name}</Text>
          </Flex>
        </LinkOverlay>
      </TableCell>
      <TableCell column={integrationsColumns[1]}>
        <ArrowForwardIcon />
      </TableCell>
    </LinkBox>
  );
};

IntegrationItem.propTypes = {
  integration: PropTypes.object,
  property: PropTypes.object
};

const integrationsColumns = [
  {
    key: 'content',
    label: 'Integrations',
    cell: {
      w: '100%',
    },
    skeletonText: {
      noOfLines: 1,
      w: '100%',
    },
  },
  {
    key: 'arrow',
    label: '',
    cell: {
      minW: '8rem',
      textAlign: 'right',
    },
    skeleton: {},
  },
];

const buildIntegrationColumns = (title) => {
  return [
    {
      ...integrationsColumns[0],
      label: title
    },
    integrationsColumns[1]
  ];
};

const IntegrationList = ({ integrations, title, propertyId }) => {
  const columns = buildIntegrationColumns(title);
  return (
    <TableList columns={columns}>
      {integrations.map((integration) => (
        <IntegrationItem key={integration.id} integration={integration} propertyId={propertyId} />
      ))}
    </TableList>
  );
};

IntegrationList.propTypes = {
  integrations: PropTypes.array,
  title: PropTypes.string,
  property: PropTypes.object
};

const fetchIntegrations = ({ query, property }) => {
  return client.properties.for(property.id).integrations.search({ query });
};

const IntegrationsView = () => {
  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const [query, debouncedQueryHandler] = useQueryDebounce(property);

  const deleteTokenRequest = useRequest(
    async ({ token }) => {
      if (!confirm(`Are you sure you want to delete the API Token ${token.name}?`)) return null; // cancel request
      await client.properties.for(property.id).tokens.for(token.id).delete();
      return true;
    }
  );

  const integrationsPagedList = usePagedList(
    fetchIntegrations,
    { query, property }
  );
  const integrationEntries = Object.entries(_.groupBy(integrationsPagedList.items, 'target'));

  const propertyId = property && property.id;

  return (
    <View>
      <View.Section>
        <div className="flex">
          <Input
            type="search"
            fluid
            defaultValue={query}
            onChange={e => debouncedQueryHandler(e.target.value)}
            icon="search"
            placeholder="Find Integration..."
            className="fg1"
          />
        </div>
      </View.Section>
      <View.Section className="mb2">
        {
          (integrationsPagedList.empty || integrationsPagedList.loading)
            ? (
              <TableList columns={integrationsColumns} isLoading={integrationsPagedList.loading}>
                <Empty isEmpty={integrationsPagedList.empty}>
                  <Text fontSize="xl">No Integrations To Display</Text>
                </Empty>
              </TableList>
            )
            : (
              integrationEntries.map(([name, integrations]) => (
                <IntegrationList
                  key={name}
                  integrations={integrations}
                  title={formatGroupTitle(name)}
                  propertyId={property?.id}
                />
              ))
            )
        }
        <Button
          as={Link}
          basic
          className="text"
          floated="right"
          to={`/${propertyId}/property-settings/integrations/add`}
        >
          + Add Integration
        </Button>
      </View.Section>
      <View.Section>
        <h3>API Tokens</h3>
        <PagedList fetchList={() => client.properties.for(property.id).tokens.list()}>
          {({ items, isEmpty }) => (
            <WaitFor waitFor={!deleteTokenRequest.loading} wrapContents>
              <List celled>
                {isEmpty && <EmptyList message="No API Tokens" />}
                {items.map(token => (
                  <List.Item key={token.id}>
                    <div className="flex aic">
                      <List.Content>
                        <List.Header className="pt1 pb1">{token.name}</List.Header>
                      </List.Content>
                      <List.Content className="mla">
                        <Button onClick={() => deleteTokenRequest.run({ token })}>DELETE</Button>
                      </List.Content>
                    </div>
                  </List.Item>
                ))}
              </List>
            </WaitFor>
          )}
        </PagedList>
        <ErrorMessage error={deleteTokenRequest.error} />
        <Button
          as={Link}
          basic
          className="text"
          floated="right"
          to={`/${propertyId}/property-settings/integrations/tokens/add`}
        >
          + Add API Token
        </Button>
      </View.Section>
    </View>
  );
};

const PropertySettingsIntegrations = IntegrationsView;

PropertySettingsIntegrations.propTypes = {
  property: PropTypes.object
};

export default PropertySettingsIntegrations;
